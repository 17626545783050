import { useTranslation } from "react-i18next";
import { SectionHeading } from "../../components/sectionHeading";
import ServiceCard from "../../components/serviceCard";

const AppServices = ({services = [] , serviceDetails = []}) => {

    const {t:trans} = useTranslation(["common","heading"]);
    return (
        <section id="OurServices" className="home-services">
            <div className="nice-container">
            <SectionHeading
                    title={trans("common:our_pricing")}
                    fontSize={6}
                    bold={true}
                    className='mb-20 text-center'
              />
              <div className="nice-row">
                {
                    services.map((item,index)=>{
                        return (
                            <div className="nice-col-xl-4 nice-col-lg-4">
                            <ServiceCard 
                            key={`Service (${index})`}
                            title={item?.title}
                            price={item?.price}
                            description={item?.description}
                            period={item?.period}
                            features={serviceDetails?.filter(val=>{
                                if(val.id === item?.id)
                                return val
                            })}
                            />
                         </div>
                        )
                    })
                }
              </div>
            </div>
        </section>
    );
};
export default AppServices;