import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../components/footer";
import { MapBanner } from "../components/mapBanner";
import NavBar from "../components/navBar";
import UpButton from "../components/upButton";
import AppFeatureDescriptor from "./components/appFeatureDescripitor";
import AppFeatures from "./components/appFeatures";
import AppFlow from "./components/appFlow";
import AppProviders from "./components/appProviders";
import AppReference from "./components/appReference";
import AppServices from "./components/appServices";
import AppStatistics from "./components/appStatistics";
import AppViewer from "./components/appViewer";
import Contact from "./components/contact";
import { Loading } from '../../features/components/loading';
import axios from "axios";
import { dataRequest } from "../../utilites/dataRequest";
const HomePage = () => {
  const {t:trans} = useTranslation(["heading","model","common"]);
  const [app,setApp] = useState({
    appData:null,
    appViewer:null,
    appFlow:null,
    appFeatures:null,
    appProviders:null,
    appFeatureDescriptor:null,
    appStatistics:null,
    appServices :null,
    appServicesDetails:null,
    points:null
  });
  const [isLoading,setIsLoading] = useState(true);
  async function fetchData(){
    try{
      const allRequests = [
       /*For NavBar*/ await dataRequest({"object" : "MainWebPage"}) , 
       /*For AppViewer */ await dataRequest({"Object":"AppImages", "option":"column","fields":"image"}),
       /*For AppFlow */ await dataRequest({ "object" : "OurAppFlow"}),
       /*For AppFeatures */  await dataRequest({"Object":"AppFeatures", "objectSettings":{"Limit":{"optional":"top","value":2}}}),
       /*For AppProviders */ await dataRequest({"name":"OurDoctors"}, "func"),
       /*For AppFeatureDescriptor  */ await dataRequest({"Object":"AppFeatures"}),
       /*For AppStatistics*/ await dataRequest({"name":"Statistic"}, "func"),
       /*For Service */ await dataRequest({"object":"palns"}, "details"),
       /*For Service Details */ await dataRequest({"name":"GetPlansFeatures",}, "func"),
       /*For Service Details */ await dataRequest({"name":"centerLocations",}, "func")
      ];
       axios.all(allRequests).then(axios.spread(
       function(navBarResponse,appViewerResponse,appFlowResponse,appFeaturesResponse,appProvidersResponse,appDescriptorResponse,appStatisticsResponse,appServicesResponse,appServiceDetailsResponse,pointsResponse){
         const model =trans("model:description");
         const appObject = {...app};
         if(navBarResponse || navBarResponse?.ApiObjectData?.length > 0){
           appObject.appData = (navBarResponse?.ApiObjectData[0]);
         }
         appObject.appViewer = appViewerResponse?.ApiObjectData ?? [];
         appObject.appFlow = appFlowResponse?.ApiObjectData ?? [];
         //--------------------------------------------------------------------------------
         appObject.appFeatures = appFeaturesResponse?.ApiObjectData?.map(item=>{
            return {
              title:item[`Main${model}`],
              description:item[`Feature${model}`],
              image:item?.Image,
              containerId:'AppFeatureDetail'}
          });
          //-------------------------------------------------------------------------------
          appObject.appProviders = appProvidersResponse?.map(item=>{
            return {
              title:item[`${model}`],
              subTitle:item[`Pos${model}`],
              image:item?.Image,
              summary:item?.summary
           }
          });
          //-------------------------------------------------------------------------------
          appObject.appFeatureDescriptor = appDescriptorResponse?.ApiObjectData?.map(item=>{
            return {
              title:item[`Main${model}`],
              description:item[`Feature${model}`],
              image:item?.Image,
              containerId:'AppFeatureDetail'}
          });
          //--------------------------------------------------------------------------------
          appObject.appStatistics = appStatisticsResponse?.map((item)=>{
            return {
             title: item[`${model}`],
             value : item.Value
          }});
          //---------------------------------------------------------------------------------
          appObject.appServices = appServicesResponse?.ApiObjectData?.map(item=>{
            return {
              id:item?.PlanID,  
              title:item[`${model}`],
              price:item?.Price,
              description:item[`Summary${model}`],
              period:item?.PeriodType
           }
          });
          //----------------------------------------------------------------------------------
         appObject.appServicesDetails = appServiceDetailsResponse?.map(item=>{
          return {
            description:item[`${model}`],
            available:item?.Available,
            id:item?.PlanID
          }});
          //-----------------------------------------------------------------------------------
          appObject.points = pointsResponse;
          //-----------------------------------------------------------------------------------
          setApp(appObject)
          setIsLoading(false);
      }));
     } catch(error){
       console.log(error);
     }
  }
  useEffect(()=>{
  fetchData();
  },[]);
   
  if(isLoading){
  return <Loading/>
  }
  else{
  return (
    <section className="home">
     
     <NavBar appData={app?.appData}/>
     <AppViewer appViews={app?.appViewer }/>
     <AppFlow hasBg={true} appViews= {app?.appFlow}/>
     <AppFeatures appViews={app?.appFeatures}/>
     <AppProviders doctors={app?.appProviders}/>
     <AppFeatureDescriptor  appViews={app?.appFeatureDescriptor}/>
     <AppStatistics values={app?.appStatistics}/>
     <AppServices services={app?.appServices} serviceDetails={app?.appServicesDetails}/>
     <AppReference 
      image={"data:image/png;base64," +  app?.appData?.MainImage}
      googleLink="https://play.google.com/store/apps/details?id=com.visualsoft.vizzet.vezzet_patient"
      appleLink="https://apps.apple.com/us/app/ziara-%D8%B2%D9%8A%D8%A7%D8%B1%D8%A9/id1608151988"
      title={app?.appData[`Main${trans("model:description")}`]}
      description={trans("common:download_title")}/>
     <Contact />
     <MapBanner values={app?.points}/>
     <Footer description1={app?.appData?.FooterDescription1}  description2={app?.appData?.FooterDescription2}/>
     <UpButton/>
    </section>
  )
 }
};

export default HomePage;