import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SectionHeading } from "../../components/sectionHeading";
import ToggleList from "../../components/toggleList";
const AppFeatures = ({defaultIndex = 0 , appViews = []}) => {
  const {t:trans} = useTranslation(["common"]);
  const [activeItem,setActiveItem] = useState(defaultIndex);
    return (
        <section id="AppFeatures" className="home-features nice-container">
          <article className="home-features-viewer">
            <img src={appViews && ("data:image/png;base64," + appViews[activeItem]?.image)} alt="app feature"/>
          </article>
          <article className="home-features-controller ">
            <SectionHeading
            title={trans("common:features")}
            fontSize={6}
            bold={true}
            />
            <ToggleList
            values={appViews}
            onItemSelected = {(selectedItem)=>{
            setActiveItem(selectedItem);
            }}
            />
          </article>
        </section>
    );
};

export default AppFeatures;