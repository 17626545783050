import { useTranslation } from 'react-i18next';

export function InputField({
    name,
    id = null,
    readOnly = false,
    disabled = false,
    type = 'text',
    onChangeValue,
    title,
    errorLabel,
    placeholder,
    secondary = false,
    value,
    required = false,
    length = 50,
    extraClasses = '',
    ...props
}) {
    const {i18n} = useTranslation();
    let lang = i18n?.language;
    const languageDir = "language-" + lang;

    return (
        <div className={`input ${errorLabel && 'input-danger'}  ${languageDir}  ${secondary && "input-secondary" }`}>
            <input disabled={disabled}
                {
                    ...props
                }
                id={id}
                name={name}
                maxLength={length}
                readOnly={readOnly}
                onChange={(e) => { 
                    if (onChangeValue)
                        onChangeValue(e);
                }}
                className={"input-field " + extraClasses}
                placeholder={placeholder}
                required={required}
                type={type}
                value={value} />
        </div>
    );
}
