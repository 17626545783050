import { concat } from "lodash";

// this function is used to format the number based on the contry currency code
export const numberFormat = (number ,displayCurrencyLiter = true, syntax = {code : 'SAR'  , locale : 'en'})=>{
const {locale} = syntax;
const lang =  localStorage.getItem("i18nextLng");
const code = lang === 'en' ? 'Rial' : 'ريال';
const suffixCode = (locale === 'en' ? 'IN' : 'EG');
if(number === null || number  === undefined || number ==='null' || number === '' || number === '0' || number === 0)
return null;

if(displayCurrencyLiter) {
    return concat(Intl.NumberFormat(locale + `-${suffixCode}`).format(number),code);
} 

return concat(Intl.NumberFormat().format(number),code);
};