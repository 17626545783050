import { Link , useLocation } from "react-router-dom";
import { SectionHeading } from "../../components/sectionHeading";
import { useTranslation } from "react-i18next";
import {  useState } from "react";
import { scrollToElement } from "../../../utilites/scrollToElement";
const AppFeatureDescriptor = ({ defaultIndex = 0  , appViews = []}) => {
    const { t: trans } = useTranslation(["form","heading"]);
    const [activeItem, setActiveItem] = useState(defaultIndex)
    const { pathname: path } = useLocation();
    return (
        <section id="AppFeatureDetail" className="home-descriptor nice-container">
            <SectionHeading
                title={trans("common:app_features")}
                fontSize={6}
                bold={true}
                className='mb-20 text-center'
            />
            <div className="nice-row">
                <div className="nice-col-xl-4 nice-col-lg-12">
                    <ul className="home-descriptor-features">
                        {
                            appViews.map((item, index) => {
                                return (
                                    <li key={index} className={`home-descriptor-features-item ${activeItem === index ? 'home-descriptor-features-item-active' : ''}`}>
                                        <Link to={path} onClick={() => setActiveItem(index)}>
                                            <div><img src={"data:image/png;base64," + item?.image} alt={item?.title} /></div>
                                            {item?.title}
                                        </Link>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                <div className="nice-col-xl-8 nice-col-lg-12">
                    <div className="nice-row items-center">
                        {
                            appViews.map((item, index) => {
                                if(index === activeItem)
                                return (
                                    <>
                                        <div key={index} className="nice-col-lg-6 nice-col-md-12">
                                            <div className="home-descriptor-features-show">
                                                <img src={"data:image/png;base64,"  + item?.image} alt={item?.title} />
                                            </div>
                                        </div>
                                        <div className="nice-col-lg-6 nice-col-md-12">
                                            <div className="home-descriptor-features-detail">
                                                <SectionHeading
                                                    title={item?.title}
                                                    fontSize={5}
                                                    bold={true}
                                                />
                                                <p>
                                                  {item?.description}
                                                </p>
                                                <Link className="toggleList-content-readMore" to={path} onClick={()=>scrollToElement(item?.element)}>{trans("form:read_more")}</Link>
                                            </div>
                                        </div>
                                    </>
                                )
                                return <></>
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};
export default AppFeatureDescriptor;