import { concat } from "lodash";

export const Card = ({img = null , title = '' , description = '' , step = 1}) => {
    return (
        <div className="card my-6">
          <img src={"data:image/png;base64," + img} alt={`card-${title}`}/>
          <h3 className="text-4 card-title font-bold text-dark-blue">{title}</h3>
          <p className="text-center  text-2 leading-6 ">{description}</p>
          <div className="card-step">
             {concat(step,'.')}
          </div>
        </div>
    )
};