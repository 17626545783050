const ProviderCard = ({image = '/assets/images/doc.png' , title = '' , subTitle = '' , summary = ''}) => {
    return (
        <div className="provider-card">
          <div className="provider-card-info">
              <div className="provider-card-info-view">
                <img src={image} alt={title}/> 
              </div>
             <div className="flex flex-col provider-card-title  nice-gap-1 nice-none">
                <h3 className="font-bold text-grey  nice-none">{title}</h3>
                <span className="text-primary text-2 font-bold nice-none">{subTitle}</span>
             </div>
          </div>
          <p className="provider-card-summary">{summary}</p>
        </div>
    );
};
export default ProviderCard;