import ProviderCard from "../../components/providerCard";
import { SectionHeading } from "../../components/sectionHeading";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper';
import { useTranslation } from "react-i18next";
const AppProviders = ({ defaultBg = '/assets/images/green-bg.png'  , doctors = []}) => {
    const {t:trans} = useTranslation(["model","common","heading"]);
    return (
        <section className="home-providers" style={{ backgroundImage: `url('${defaultBg}')` }}>
            <div className="nice-container">
                <SectionHeading
                    title={trans("common:our_doctors")}
                    fontSize={6}
                    bold={true}
                    className='text-white mb-10'
                />
                {
                    <Swiper
                        modules={[Autoplay]}
                        spaceBetween={15}
                        slidesPerView={1}
                        breakpoints={{
                            900: {
                                slidesPerView: 2,
                            },
                            1200: {
                                slidesPerView: 3,
                            }
                        }}
                        watchSlidesProgress
                        resizeObserver={true}
                        autoplay={true}
                        loop={true}

                    >
                        {
                           doctors.map((item, index) => {
                                return (
                                    <SwiperSlide key={index} className="flex justify-center items-center">
                                        <ProviderCard
                                            image={item?.image ? "data:image/png;base64," + item?.image : "/assets/images/default.png"}
                                            title={item?.title}
                                            subTitle={item?.subTitle}
                                            summary= {item?.summary || item?.subTitle}
                                        />
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>

                }
            </div>
        </section>
    )
};

export default AppProviders;