import { useTranslation } from "react-i18next";
import { SectionHeading } from "../../components/sectionHeading";

const AppReference = ({image , title = '' , description = '' , googleLink = '' , appleLink = ''}) => {
  const {t:trans} = useTranslation("form");
    return (
        <section className="home-reference nice-container">
            <div className="nice-row items-center">
                <div className="nice-col-md-6 nice-col-xl-6">
                    <img src={image ?? "/assets/images/app_show.png"}  alt='main app logo'/>
                </div>
                <div className="nice-col-md-6 nice-col-xl-6">
                    <SectionHeading
                        title={title}
                        fontSize={6}
                        bold={true}
                        className='mb-10'
                    />
                    <p className="text-grey text-2">{description}</p>
                    <div className="flex flex-row my-8 flex-wrap nice-gap-1">
                      <a href={googleLink} title={googleLink} className='home-reference-btn'>
                        <img src="/assets/icons/google_play2.png" alt="google_play"/>
                        <div className="flex flex-col items-center justify-center">
                          <div className="text-2 font-bold text-grey">
                            {trans("form:available")}
                          </div>
                          <div className="text-2 text-primary">
                          {trans("form:google_play")}
                          </div>
                        </div>
                      </a>
                      <a href={appleLink} title={appleLink} className='home-reference-btn nice-bg-primary'>
                        <img src="/assets/icons/apple.png" alt="apple_store"/>
                        <div className="flex flex-col items-center justify-center">
                          <div className="text-2 font-bold text-white">
                          {trans("form:available")}
                          </div>
                          <div className="text-2 text-white">
                          {trans("form:apple_store")}
                          </div>
                        </div>
                      </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AppReference;