import { useState, useEffect } from "react";
import { scrollToElement } from "../../utilites/scrollToElement";

const UpButton = ({ target = 'AppNav' }) => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);
    
    return <>
        {
            isVisible && <div className='page-btn' onClick={() => { scrollToElement(target) }}>
                <img src='/assets/icons/arrow-up.svg' alt='arrow-up' />
            </div>
        }
    </>
};
export default UpButton;