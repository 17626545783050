import { SectionHeading } from "./sectionHeading";
import { numberFormat } from "../../utilites/currencyFormatter";
import Link from "react-scroll/modules/components/Link";
import { useState } from "react";
import { useTranslation } from "react-i18next";
const ServiceCard = ({ title = '', price = '', description = '', period = '', features = []  , to = '/'}) => {
    const [active,setActive] = useState(false);
    const {t:trans} = useTranslation("common");
    return (
        <article className={`home-services-card ${active && 'home-services-card-active'}`}>
            <SectionHeading
                title={title}
                fontSize={4}
                bold={true}
            />
            <div className="flex flex-row items-center ">
                <span className="home-services-card-price">{numberFormat(price)}</span>
                {period && <sub className="home-services-card-period">/ {period}</sub>}
            </div>
            <p className="text-grey text-2 my-3">{description}</p>
            <div className="nice-line"></div>
            <ul className="home-services-card-list">
                {
                    features.map((item, index)=>{
                        return (
                            <li key={index} className="home-services-card-list-item">             
                            { 
                              item?.available ? 
                              <span className='home-services-card-list-item-mark nice-bg-light-grey'>&#10004;</span> 
                             :<span className='home-services-card-list-item-mark nice-bg-danger text-white'>&#10006;</span>
                            }
                            {item?.description}
                            </li>
                        )
                    })
                }
            </ul>
            <Link className={`home-services-card-btn my-2 ${active ? 'nice-bg-dark-blue' : 'nice-bg-primary'}`} onClick={()=>setActive(!active)} to={to}>{trans("common:choose")}</Link>
        </article>
    );
};

export default ServiceCard;