import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {Map} from "./map";
export function MapBanner({values = []}) {
    const [renewValues,setRenewValues] = useState([]); 
    const {t:trans} = useTranslation("model");
    
    useEffect(()=>{
      const data = values.map(item=>{
        return {description : item[`${trans("model:description")}`] ,  lngOfHospital:item?.lngOfHospital , latOfHospital : item?.latOfHospital}
      });
      setRenewValues(data);
    },[]);

    return (
        <div className=" mx-10 mb-8 home-map">
          <div className="nice-container home-map-container">
          {renewValues && <Map  markers = {renewValues} />}
          </div>
        </div>
    )

}

