import axios from "axios";
import config from '../configuration/config.json';
export const dataRequest = async (request , actionType = 'details' , methodType = 'post')=>{
    try{
       const response =  await axios.request({
            baseURL: config.baseURL,
            url: actionType,
            method: methodType,
            data : request,
            headers:{
                "user-authentication":config.secret.user,
                "password-authentication":config.secret.pass
            }
        });
    return response?.data;
    }catch(error){
        console.log(error);
        return undefined; 
    }
}

