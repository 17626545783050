export function formValidate(schema,data){  
const {error,value} =   schema.validate(data,{abortEarly:false});
if(!error) return null;
//alert(JSON.stringify(error.details));
const errors ={};
for(let item of error.details){
    if(item.type != 'object.unknown')
        errors[item.path[0]] = item.message;
}
return errors;
}

export function validateProperty({name,value},schema){
const obj = {[name]:value};
const {error} = schema.validate(obj,{abortEarly:false});
return error ? error.details[0].message : null;
}