import Joi from "joi";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormButton } from "../../components/inputs/formButton";
import { InputField } from "../../components/inputs/_input";
import { TextArea } from "../../components/inputs/_textArea";
import { SectionHeading } from "../../components/sectionHeading";
import { formValidate } from '../../../utilites/validation';
import { dataRequest } from "../../../utilites/dataRequest";

const Contact = () => {
    const { t: trans } = useTranslation(["common"]);
    const [request, setRequest] = useState({
        name: '',
        phoneNumber: '',
        email: '',
        message: ''
    });

    const schema = Joi.object({
        name: Joi.string().max(200).required(),
        phoneNumber: Joi.string().min(9).max(15).required(),
        email: Joi.string().email({ minDomainSegments: 2, tlds: { allow: ['com'] } }).required(),
        message: Joi.string().max(999).required()
    });

    const [error, setError] = useState({});

    const handleRequestData = async () => {
        const result = formValidate(schema, request) ?? {};
        setError(result);
        try {
            if (Object.keys(result).length === 0) {
                    await dataRequest({
                    "object":"contact with us",
                    "values":{
                        "name":request?.name?.trim(),
                        "email":request?.email?.trim(),
                        "phone":request?.phoneNumber?.trim(),
                        "massage":request?.message?.trim()
                    }
                   }, "add"
                 , "post");
                 setError({});
                 setRequest({
                    name: '',
                    phoneNumber: '',
                    email: '',
                    message: ''
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <section id='ContactUs' className="home-contact nice-container">
            <div className="nice-row">
                <div className="nice-col-xl-6 nice-col-lg-6">
                    <img src="/assets/images/contact_us.png" alt="contact_us" />
                </div>
                <div className="nice-col-xl-6 nice-col-lg-6">
                    <SectionHeading
                        title={trans("common:contact_us")}
                        fontSize={6}
                        bold={true}
                        className='mb-10'
                    />
                    <InputField
                        id='name'
                        value={request?.name}
                        length={200}
                        errorLabel={error?.name}
                        placeholder={trans("form:name")}
                        onChangeValue={(value) => {
                            const val = { ...request };
                            val.name = value.target.value;
                            setRequest(val);
                        }}
                    />
                    <InputField
                       id='email'
                       value={request?.email}
                        length={100}
                        errorLabel={error?.email}
                        placeholder={trans("form:email")}
                        onChangeValue={(value) => {
                            const val = { ...request };
                            val.email = value.target.value;
                            setRequest(val);
                        }}
                    />
                    <InputField
                        id='phone'
                        value={request?.phoneNumber}
                        length={15}
                        errorLabel={error?.phoneNumber}
                        placeholder={trans("form:phone")}
                        onChangeValue={(value) => {
                            const val = { ...request };
                            val.phoneNumber = value.target.value;
                            setRequest(val);
                        }}
                    />
                    <TextArea
                        id='message'
                        value={request?.message}
                        placeholder={trans("form:message")}
                        errorLabel={error?.message}
                        onChangeValue={(value) => {
                            const val = { ...request };
                            val.message = value;
                            setRequest(val);
                        }}
                    />
                    <div className="nice-row">
                        <div className="nice-col-md-6">
                            <FormButton title={trans("form:send")}
                                onClick={handleRequestData}
                                isSwitch={true}
                                defaultBgColor='primary'
                                defaultTextColor="white"
                                switchBgColor='transparent'
                                switchTextColor='black'
                                switchBorder={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Contact;