import 'swiper/scss';
import 'swiper/scss/autoplay';
import React from 'react';
import { Route, Routes } from 'react-router';
import HomePage from './features/home';
import { useTranslation } from 'react-i18next';
import NotFoundPage404 from './features/errors/404';
function App() {
  const { i18n } = useTranslation();
  const lang = i18n?.language ?? 'ar';
  const languageDir = 'language-' + lang;
  const baseUrl = (lang === 'en' || lang === '') ? '' : '/'+lang;
  return (
    <main className={`App ${languageDir}`}>
        <Routes>
          <Route path={baseUrl + "/"} element={<HomePage />} />
          <Route path='*' element={<NotFoundPage404/>}/>
        </Routes>
    </main>
  );
}
export default App;
