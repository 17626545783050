import {useState} from "react";
import {GoogleMap, Marker, InfoWindow} from "@react-google-maps/api";
export function Map({markers = [] }) {
    const [activeIndex , setActiveIndex] = useState(null);
    const mapStyles = {
        height: "100%",
        width: "100%"
    };

    const infoWindowStyle ={
        backgroundColor:'#fff',
        color:'#000',
        textAlign:'center',
        minWidth:'100px',
        fontSize:18,
        fontWeight:'bold'
    };

    return (
            <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={8}
                center={{ lat: 21.1842328, lng: 51.267487 }}
                clickableIcons={false}
                options={
                    {
                        panControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                        streetViewControl: false,
                        zoomControl: true,
                    }

                }
            >
                {
                        markers.map((item,index)=>{
                        const { latOfHospital, lngOfHospital, description } = item;
                        if(latOfHospital != null && lngOfHospital != null)
                        return (
                            <Marker
                                position={{ lat: parseFloat(latOfHospital), lng: parseFloat(lngOfHospital) }}
                                key={index}
                                label={`م${index + 1}`}
                                title={`م${index + 1}`}
                                onClick={()=>{
                                    setActiveIndex(index);
                                }}
                            >
                                   
                             { activeIndex == index && <InfoWindow
                             onCloseClick={()=>{
                                 setActiveIndex(null);
                             }}
                             >
                                 <div className="bg-white text-center font-bold text-2">
                                   {description}
                                 </div>
                                </InfoWindow>
                             }
                            </Marker>
                        )
                    })
                }
            </GoogleMap>
    )

}

