import { useState } from "react";
export const FormButton = (
    {  
       title = '',
       isSwitch = false 
     , className = 'btn text-2 uppercase'
     , defaultTextColor = 'black' 
     , defaultBgColor = 'white' 
     , switchTextColor = null 
     , switchBgColor = null 
     , shadow = true 
     , switchBorder = false
     , defaultBorderColor
     , onClick})=>{
    const _className = className ?? '';
    const [bgColor,setBgColor] = useState(defaultBgColor);
    const [textColor,setTextColor] = useState(defaultTextColor);
    const [isActive,setIsActive] = useState();

    const handleOnSwitch = ()=>{
      if(!isSwitch){
         return;
      }
      if(isActive){
        setBgColor(switchBgColor);
        setTextColor(switchTextColor);
      }else{
        setBgColor(defaultBgColor);
        setTextColor(defaultTextColor);
      }
    };

    return (
        <div 
        style={{outline:(switchBorder && !isActive) ? `.1rem solid var(--${defaultBgColor})` : null , border: defaultBorderColor && `.1rem solid var(--${defaultBorderColor})`}}
        className={`${_className} ${shadow && 'nice-shadow'} nice-bg-${bgColor} text-${textColor} `}
        onClick={()=>{
            if(onClick){
                onClick();
            }
        }}
        onMouseEnter={()=>{
          setIsActive(false);
          handleOnSwitch();
        }}
        onMouseLeave={()=>{
         setIsActive(true);
         handleOnSwitch();
        }}
        >
          {title}
        </div>
    );
};
