import { StatisticInfo } from "../../components/statisticInfo";
const AppStatistics = ({ defaultBg = '/assets/images/green-bg.png' , values = []}) => {
    return (
        <section className="home-statistics" style={{ backgroundImage: `url('${defaultBg}')` }}>
            <div className="nice-container">
                <div className="nice-row items-center ">
                    {
                        values.map((item, index) => {
                            return (
                              <div className="nice-col-md-3">
                                <StatisticInfo maxValue={item?.value} title={item?.title} key={`statistic-info-(${index})`}/>
                              </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    );
};

export default AppStatistics;

