import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper';
const AppViewer = ({appViews=[]}) => {
    return (
        <div className="home-viewer">
            <Swiper
                modules={[Autoplay]}
                spaceBetween={5}
                slidesPerView={1}
                breakpoints={{
                    576: {
                        slidesPerView: 3,
                    },
                    900: {
                        slidesPerView: 4,
                    },
                }}
                watchSlidesProgress
                resizeObserver={true}
                autoplay={true}
                loop={true}

            >
                {
                    appViews.map((item, index) => {
                        return (
                            <SwiperSlide key={index} className="flex justify-center items-center">
                                <img className="object-full object-contain" src={item?.image ?"data:image/png;base64," +  item?.image : ''} alt={`app-Show-${index}`} />
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </div>
    )
};

export default AppViewer;