import { useCallback, useEffect, useState } from "react";
import useWindowPosition from "../hooks/useWindowPosition";

export const StatisticInfo = ({ title = '', maxValue = 0, duration = 50}) => {
    const [value, setValue] = useState(0);
    const pageY = useWindowPosition();
    const [stopCounter,setStopCounter] = useState(false);
    useEffect(() => {
    callBack();
    }, [pageY]);
    const callBack = useCallback(()=>{
        try {
            if(pageY > 4000 && !stopCounter && value !== maxValue){
            let initialValue = 0;
            const timer = setInterval(() => {
                initialValue += 1000;
                setValue(initialValue);
                if (initialValue >= maxValue) {
                    setValue(maxValue);
                    setStopCounter(true);
                    clearInterval(timer);
                }
            }, duration);
        }
        } catch (error) {
            console.log(error);
        }
    },[pageY]);

    return (
        <article
            className="flex flex-col items-center nice-gap-1">
            {
                <h3 className="text-7 font-bold text-white  transition-all duration-75 ease-out">
                {value?.toLocaleString('en-us')}
                </h3>
            }
            <div className="text-3 font-bold text-white">{title}</div>
        </article>
    );
};