import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { scrollToElement } from "../../utilites/scrollToElement";
const ToggleList = ({ activeIndex = 0, values = [] , onItemSelected}) => {
    const { pathname: path } = useLocation();
    const [activeItem, setActiveItem] = useState(activeIndex);
    const { t: trans } = useTranslation("form");
    return (
        <div>
            {values && <ul className="toggleList">
                {
                    values.map((item, index) => {
                        return (
                            <li
                                key={`feature-${index}`}
                                className={`toggleList-item ${activeItem === index ? 'nice-active' : ''}`}
                                onClick={() => {
                                    if(onItemSelected){
                                        onItemSelected(index);
                                    }
                                    setActiveItem(index);
                                }}>
                                <Link to={path}>{item?.title}</Link>
                            </li>
                        )
                    })
                }
            </ul>
            }
            {
                values.map((item, index) => {
                    if(index === activeItem)
                    return (
                        <div key={`feature-content-${index}`} className="toggleList-content">
                            <p>{item?.description}</p>
                            <Link className="toggleList-content-readMore" to={path} onClick={()=>scrollToElement(item?.containerId)}>{trans("form:read_more")}</Link>
                        </div>
                    )
                    return <></>
                })
            }
        </div>
    );
};
export default ToggleList;