import {useState} from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import {Link} from 'react-scroll';
import { scrollToElement } from "../../utilites/scrollToElement";
import { FormButton } from "../components/inputs/formButton";
const NavBar = ({
iconUrl = "https://his.amihealthcare.co/account/register",
getStartUrl = "https://his.amihealthcare.co/account/register",
readMoreDirectorElement = 'AppFlow',
appData = {}
})=>{
 const defaultBg = '/assets/images/app_show_bg.png';
 const [toggle, setToggle] = useState(true);
 const {t:trans} = useTranslation(["common","form","model"]);
 const {pathname:path} = useLocation();

 return (
    <nav id='AppNav' className="navbar" style={{ backgroundImage: `url('${defaultBg}')` }}>
    <section className="navbar-box">
      <div>
        <div className="navbar-box-btn"
          onClick={() => {
            setToggle(!toggle);
          }}>
          <img src={`/assets/icons/${toggle ? 'menu' : 'delete'}.svg`} alt='toggle icon'/>
        </div>
         <ul className="navbar-box-items  navbar-box-items-big">
          <li><Link className="nice-pointer" to={path}>{trans("common:home")}</Link></li>
          <li><Link className="nice-pointer" to={path} onClick={()=>scrollToElement('AppFlow')} >{trans("common:our_steps")}</Link></li>
          <li><Link className="nice-pointer" to={path} onClick={()=>scrollToElement('AppFeatures')}>{trans("common:features")}</Link></li>
          <li><Link className="nice-pointer" to={path} onClick={()=>scrollToElement('OurServices')} >{trans("common:our_prices")}</Link></li>
          <li><Link className="nice-pointer" to={path} onClick={()=>scrollToElement('ContactUs')}>{trans("common:contact_us")}</Link></li>
        </ul>
        <ul className={`navbar-box-items  navbar-box-items-small ${toggle && 'hidden'}`}>
        <li><Link className="nice-pointer" to={path}>{trans("common:home")}</Link></li>
          <li><Link className="nice-pointer"  to={path} onClick={()=>scrollToElement('AppFlow')} >{trans("common:our_steps")}</Link></li>
          <li><Link className="nice-pointer"  to={path} onClick={()=>scrollToElement('AppFeatures')}>{trans("common:features")}</Link></li>
          <li><Link className="nice-pointer"  to={path} onClick={()=>scrollToElement('OurServices')} >{trans("common:our_prices")}</Link></li>
          <li><Link className="nice-pointer"  to={path} onClick={()=>scrollToElement('ContactUs')}>{trans("common:contact_us")}</Link></li>
        </ul>
      </div>
      <a href={iconUrl} title='amihealthcare logo'><img className="navbar-box-img" alt='platform logo'  src="/assets/images/Ziarah_logo.png" /></a>
    </section>
    <section className="navbar-container nice-container-fluid">
    <div className="nice-row items-center">
     <div className="nice-col-xl-4 nice-col-lg-6 nice-col-md-6 order-1">
     <img src={appData?.MainImage ? "data:image/png;base64," +  appData?.MainImage  : "/assets/images/app_show.png"} alt='main show_app' className="object-full base-3/5 order-2 object-contain my-4 navbar-container-img-show" />
     </div>
     <div className="nice-col-xl-8 nice-col-lg-6 nice-col-md-6">
     <div className="navbar-container-info">
        <div className="flex flex-col nice-gap-2 object-full order-1">
          <h1 className="navbar-container-info-title">{appData[`Main${trans("model:description")}`]}</h1>
          <p className="text-white  text-2">{appData[`Second${trans("model:description")}`]}</p>
            <div className="btn-groups">
              <FormButton
                title={trans("form:get_started")}
                onClick={() => {
                 window.location = getStartUrl;
                }}
                isSwitch={true}
                defaultBgColor='white'
                defaultTextColor="black"
                switchBgColor='transparent'
                switchTextColor='white'
                switchBorder={true}
              />
              <FormButton
                title={trans("form:read_more")}
                onClick={() => {
                  scrollToElement(readMoreDirectorElement)
                }}
                defaultBgColor='transparent'
                defaultTextColor="white"
                defaultBorderColor='white'
              />
            </div>
          </div>
     </div>
     </div>
    </div>
    </section>
  </nav>
)
};

export default NavBar;