import { useTranslation } from "react-i18next";
import { Card } from "../../components/card";
import { SectionHeading } from "../../components/sectionHeading";
const AppFlow = ({hasBg = false , defaultBg = '/assets/images/wave.png' , appViews = []})=>{
    const {t:trans} = useTranslation(["common","model"]);
    return (
        <section  id='AppFlow' className="home-flow p-8" style={{backgroundImage:hasBg ? `url('${defaultBg}')` : null}}>
            <div className="container flex flex-col items-center justify-center">
            <SectionHeading
            title={trans("common:our_steps")}
            fontSize={6}
            bold={true}
            className='my-4'
            />
            <div className="nice-container">
                <div className="nice-row">
                     {
                         appViews.map((item,index)=>{
                             return (
                                <div  key={`Card-${index}`} className="nice-col-md-4">
                                <Card 
                                title={item[`Main${trans("model:description")}`]}
                                description={item[`Image${trans("model:description")}`]}
                                img={item?.Image}
                                step={item?.ImageNum ?? index + 1}
                                />
                               </div>
                             )
                         })
                     }
                </div>
            </div>
            </div>
        </section>
    );
};
export default AppFlow;